import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/content-aware-scaling-in-photoshop",
  "date": "2013-05-13",
  "title": "CONTENT AWARE SCALING IN PHOTOSHOP",
  "author": "admin",
  "tags": ["design", "photoshop"],
  "featuredImage": "feature.jpg",
  "excerpt": "In Photoshop CS6, content-aware scaling (also known as seam carving) is a cool image-sizing feature."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Advantages:`}</p>
    <ul>
      <li parentName="ul">{`Resize and reshape your images without the distortion of the content and composition. `}</li>
      <li parentName="ul">{`Preserves image quality much better than traditional transformations. `}</li>
    </ul>
    <p>{`Here’s how to apply content-aware scaling:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Choose your desired layer in the Layers panel. If you want to isolate the scaling to a selection on the layer, make that selection now.Content-aware scaling doesn’t work on adjustment layers, layer masks, individual channels or Smart Objects. If you’re scaling a Background layer, choose Select→All. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Choose Edit→Content-Aware Scale. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Specify your options on the Options bar as follows:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Reference Point: `}</em>{` Click a square in the reference point box to specify the axis point. The default location is the center. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Use Relative Positioning for Reference Point (triangle icon): `}</em>{` Select this option to specify the new axis point in relation to its current position. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Amount: `}</em>{` This option specifies the ratio of content-aware scaling to normal scaling. To minimize distortion, specify your Threshold amount. Start with a higher percentage and then adjust accordingly, if necessary. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Protect: `}</em>{` You can designate areas that you want to protect from scaling by selecting them and saving them as alpha channels. If you have an alpha channel, choose it from this submenu. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Protect Skin Tone (man icon): `}</em>{` Select this option to preserve skin tones from distorting when scaling. `}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Click and drag one of the handles of the scale box that surrounds your layer or selection to resize your image.You can upscale (make your image bigger) or downscale (make your image smaller). You can also use the Horizontal and Vertical Scale numerical fields on the Options bar. Select Maintain Aspect Ratio to keep the scaling proportional (chain icon). `}</li>
      <li parentName="ul">{`When you have completed your scaling, double-click inside the scale box, or press Enter (Return on the Mac) on your keyboard. See the difference between a layer resized with Content-Aware Scale and a layer resized with Free Transform? Note how there is less distortion with the first method in content aware scaling in photoshop. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      